<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>综合统计</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>

    <el-col :span="24" class="warp-main">

      <el-row class="first-row" :gutter="20">
        <el-col :span="4">
          <div class="block b1">
            <div class="number">{{statisticCount.foodsCategoryCount}}</div>
            美食统计
          </div>
        </el-col>
        <el-col :span="4">
          <div class="block b2">
            <div class="number">{{statisticCount.sceneryCount}}</div>
            景点统计
          </div>
        </el-col>
        <el-col :span="4">
          <div class="block b3">
            <div class="number">{{statisticCount.strategyCount}}</div>
            攻略统计
          </div>
        </el-col>
        <el-col :span="4">
          <div class="block b4">
            <div class="number">{{statisticCount.ticketCount}}</div>
            票务统计
          </div>
        </el-col>
        <el-col :span="4">
          <div class="block b3">
            <div class="number">{{statisticCount.accommodationCount}}</div>
            住宿统计
          </div>
        </el-col>
        <el-col :span="4">
          <div class="block b4">
            <div class="number">{{statisticCount.tourCount}}</div>
            一日游统计
          </div>
        </el-col>
      </el-row>

      <el-row class="second-row" :gutter="20">
        <el-col :span="12">
          <div id="users" class="block b1">用户统计</div>
        </el-col>
        <el-col :span="12">
          <div id="merchants" class="block b2">商户统计</div>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
  // let hireBusChart="";
  import echarts from 'echarts';
  import {reqAdminStatistic,reqAdminGraphicStatistics} from '../api/system-api';
  export default {
    data() {
      return {
          statisticCount:{
              foodsCategoryCount:0,
              sceneryCount:0,
              strategyCount:0,
              ticketCount:0,
              accommodationCount:0,
              tourCount:0,
          },
          userArray:[],
          merchantArray:[],
      }
    },

    methods: {
      getStatistic() {
        var that=this;
          reqAdminStatistic({}).then((res) => {
              if (res.data.code === 1) {
                  that.statisticCount = res.data.data;
              }
          })
        },
        getHouseResidence() {
            var that = this;
            reqAdminGraphicStatistics({}).then((res) => {
                if (res.data.code == 1) {
                    console.log(res);
                    that.userArray = res.data.data.wechatUsers.split(',');
                    that.merchantArray = res.data.data.merchants.split(',');
                    that.createUserChart();
                    that.createMerchantChart();
                }
            });
        },
        createUserChart(){
            var labelOption = {
                normal: {
                    show: true,
                    align: 'center',
                    verticalAlign: 'middle',
                    position: 'insideBottom',
                    distance: 15,
                    formatter: function (params) {
                        return params['value'] == 0 ? '' : params['value']
                    },
                    fontSize: 16,
                    rich: {
                        name: {
                            textBorderColor: '#fff'
                        }
                    }
                }
            };
            let userChart = echarts.init(document.getElementById('users'));
            userChart.setOption({
                title: {
                    text: '用户统计',
                    left: 'center',
                    padding: [3, 5]
                },
                color: ['#2a5d5b'],
                tooltip: {},
                legend: {
                    data: ['用户统计'],
                    bottom: 0
                },
                toolbox: {
                    show: true,
                    feature: {
                        dataView: {show: true, readOnly: false},
                        magicType: {show: true, type: ['line', 'bar']},
                        restore: {show: true},
                        saveAsImage: {show: true}
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {show: true},
                        data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        minInterval: 1
                    }
                ],
                series: [
                    {
                        name: '用户统计',
                        type: 'bar',
                        label: labelOption,
                        data: this.userArray
                    },
                ],
            });
            window.addEventListener('resize', function () {
                userChart.resize();
            });
        },
        createMerchantChart(){
            var labelOption = {
                normal: {
                    show: true,
                    align: 'center',
                    verticalAlign: 'middle',
                    position: 'insideBottom',
                    distance: 15,
                    formatter: function (params) {
                        return params['value'] == 0 ? '' : params['value']
                    },
                    fontSize: 16,
                    rich: {
                        name: {
                            textBorderColor: '#fff'
                        }
                    }
                }
            };
            let merchantChart = echarts.init(document.getElementById('merchants'));
            merchantChart.setOption({
                title: {
                    text: '商户统计',
                    left: 'center',
                    padding: [3, 5]
                },
                color: ['#e8c080'],
                tooltip: {},
                legend: {
                    data: ['商户统计'],
                    bottom: 0
                },
                toolbox: {
                    show: true,
                    feature: {
                        dataView: {show: true, readOnly: false},
                        magicType: {show: true, type: ['line', 'bar']},
                        restore: {show: true},
                        saveAsImage: {show: true}
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {show: true},
                        data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        minInterval: 1
                    }
                ],
                series: [
                    {
                        name: '商户统计',
                        type: 'bar',
                        label: labelOption,
                        data: this.merchantArray
                    },
                ],
            });
            window.addEventListener('resize', function () {
                merchantChart.resize();
            });
        }
   },
    mounted() {
      this.getStatistic();
      this.getHouseResidence();
    }
}
</script>
<style scope="scoped">
  .block {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 3px;
    text-align: center;
  }

  .block .number {
    font-size: 50px;
    color: #666;
  }

  .first-row .block {
    height: 80px;
    border: 1px solid #ddd;
    cursor: pointer;
  }

  .first-row .block.b1 { background-color: rgba(187, 222, 214, .4); border: 1px solid #bbded6;}
  .first-row .block.b2 { background-color: rgba(250, 227, 217, .4); border: 1px solid #fae3d9;}
  .first-row .block.b3 { background-color: rgba(217, 217, 243, .4); border: 1px solid #d9d9f3;}
  .first-row .block.b4 { background-color: rgba(246, 194, 194, .4); border: 1px solid #f6c2c2;}
  .first-row .block.b5 { background-color: rgba(255, 241, 193, .4); border: 1px solid #fff1c1;}
  .first-row .block.b6 { background-color: rgba(234, 255, 208, .4); border: 1px solid #e0f8c3;}

  .second-row .block {
    height: 500px;
    border: 1px solid #ddd;
  }
  .second-row .el-col {
    position: relative;
  }
  .second-row .el-select {
    position: absolute;
    right: 180px;
    top: 15px;
    z-index: 99;
  }
  .second-row .el-select .el-input {
    width: 100px;
  }
  .second-row .el-select .el-input .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  .second-row .el-select .el-input .el-input__suffix .el-input__icon {
    line-height: 30px;
  }
</style>

